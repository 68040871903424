<template>
    <div class="app-container calendar-list-container" style="padding: 0px 20px;">
        <div class="filter-container">
            <!-- <el-select v-model="searchValue.type" :disabled="multiSearch" clearable filterable placeholder="搜索类型" style="width: 150px;" class="filter-item">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-input @keyup.enter.native="handleFilter()" :disabled="multiSearch" style="width: 200px;" class="filter-item" placeholder="请输入搜索内容" v-model="searchValue.data">
            </el-input>
            <el-button class="filter-item" type="primary" :disabled="multiSearch" v-waves icon="el-icon-search" @click="handleFilter()">搜索</el-button>
            <el-button v-show="!multiSearch" class="filter-item" icon="el-icon-more" type="text" @click="multiSearch=!multiSearch">复合搜索</el-button>
            <el-button v-show="multiSearch" class="filter-item" icon="el-icon-arrow-up" type="text" @click="multiSearch=!multiSearch">收起搜索</el-button>-->
            <!-- <div class="filter-item-right">
              <el-button class="filter-item" @click="dialogVisible=true" type="primary" icon="el-icon-edit-outline">发送消息</el-button>
            </div>-->
            <!-- <transition name="el-zoom-in-top">
              <div v-show="multiSearch" class="multisearch">
                <el-input placeholder="请输入内容" v-model="listQuery.filter.name" style="width:250px">
                  <template slot="prepend">名称</template>
                </el-input>
                <el-button class="filter-item" type="primary" size="small" style="margin-top:6px" v-waves icon="el-icon-search" @click="handleFilter1()">搜索</el-button>
                <el-button class="filter-item" type="primary" size="small" style="margin-top:6px" v-waves icon="el-icon-circle-close-outline" @click="handleRestFilter()">清空条件</el-button>
              </div>
            </transition>-->
        </div>

        <el-table
                :key="tableKey"
                ref="table"
                v-loading="listLoading"
                :data="list"
                :height="tableHeight"
                element-loading-text="加载中..."
                border
                fit
                highlight-current-row
                style="width: 100%"
        >
            <el-table-column type="index" align="center" label="序号" width="100" />
            <el-table-column min-width="100" align="center" label="姓名">
                <template slot-scope="scope">
                    <span>{{ scope.row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="100" align="center" label="手机">
                <template slot-scope="scope">
                    <span>{{ scope.row.phone }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="100" align="center" label="意见">
                <template slot-scope="scope">
                    <span style="color:#E6A23C">{{ scope.row.tasteType }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="100" align="center" label="其他建议">
                <template slot-scope="scope">
                    <span>{{ scope.row.description }}</span>
                </template>
            </el-table-column>
            <el-table-column width="80" align="center" label="图片">
                <template slot-scope="scope">
                    <el-button
                            v-if="scope.row.imgList.length>0"
                            icon="el-icon-picture"
                            type="primary"
                            size="mini"
                            circle
                            @click="showImg(scope.row)"
                    />
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    align="center"
                    :label="'状态'"
                    class-name="small-padding fixed-width"
            >
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.status"
                            style="display: block"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-text="审核通过"
                            inactive-text="未通过"
                            :active-value="1"
                            :inactive-value="0"
                            @change="switchChange(scope.row)"
                    />
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-container">
            <el-pagination
                    background
                    :current-page="listQuery.page"
                    :page-sizes="[10,20,30, 50]"
                    :page-size="listQuery.count"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            />
        </div>

        <el-dialog title="查看图片" :visible.sync="imgVisible" :width="imgDialogWidth">

                <el-image
                        v-for="(img,imgIndex) in imgList"
                        :key="imgIndex"
                        style="width: 300px; height: 300px"
                        :src="img"
                        fit="cover"
                        :preview-src-list="imgList"
                        class="mr20 t-border mb10"
                ></el-image>

        </el-dialog>
    </div>
</template>

<script>
    import api from "../../../util/extra-api";
    import { local} from "../../../util/util";
    export default {
        name: "Audit",
        data() {
            return {
                tableKey: 0, // 表格的键
                list: null, // 表格的数据
                total: null, // 表格的数据数量
                listLoading: true, // 表格加载状态
                tableHeight: 450,
                listQuery: {
                    companyId: null,
                    page: 1, // 表格的页面值
                    limit: 20 // 表格的单页数量
                },
                replyData: {
                    fb_id: null,
                    reply_detail: ""
                },
                imgList: [],
                imgDialogWidth: null,
                replyDialogVisible: false,
                imgVisible: false,
                userDialogVisible: false,
                dialogVisible: false,
                userData: "",
                stUserData: null,
                selectedUser: [],
                multipleSelection: [],
                userNum: 0,
                showSend: false,
                rules: {
                    title: [{ required: true, message: "必填项", trigger: "blur" }],
                    content: [{ required: true, message: "必填项", trigger: "blur" }]
                },
                baseUrl: process.env.VUE_APP_BASE_API
            };
        },
        created() {
            //this.getList();
        },
        mounted() {
          this.listQuery.companyId = local.get('company_id')
            this.getList();
        },
        methods: {
            // toggleSelection() {
            //   this.selectedUser.forEach(u => {
            //     this.$refs.multipleTable.toggleRowSelection(u);
            //   });
            // },
            getList() {
                this.listLoading = true;
                api.get('/v1/pc/evaluation/evaluateList',this.listQuery).then(response => {
                    this.tableHeight =
                        window.innerHeight - this.$refs.table.$el.offsetTop - 85;
                    const results = response.data;
                    this.list = results;
                    this.total = response.total;
                    this.listLoading = false;
                });
            },
            switchChange(val) {
                api.put('/v1/pc/evaluation/audit/' + val.id,'').then(response => {
                    this.getList();
                });
            },

            showImg(row) {
                if (row.imgList.length == 1) {
                    this.imgDialogWidth = "25%";
                } else if (row.imgList.length == 2) {
                    this.imgDialogWidth = "30%";
                } else {
                    this.imgDialogWidth = "40%";
                }
                this.imgList = row.imgList;
                this.imgVisible = true;
            },
            // 表格单页数据数量的切换
            handleSizeChange(val) {
                this.listQuery.count = val;
                this.getList();
            },
            // 表格页数的切换
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.selectedUser = this.multipleSelection;
                this.userNum = this.selectedUser.length;
            },
        }
    };
</script>

<style  rel="stylesheet/scss" lang="scss">
    .task-form {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }
    .filter-item-right {
        display: block;
        float: right;
        margin-bottom: 10px;
    }
</style>
